<template>
  <div class="BigBoxOne">
    <div class="ConBox">
      <div class="MyPingJia">支付流水</div>
      <div class="Con">
        <div class="SouSBox">
          <img src="../../../assets/PersonalCenter/SouSuo.png" alt="">
          <input type="text" placeholder="请输入商品标题搜索">
        </div>
        <div class="TiTle">
          <div class="ShopInformation">商品</div>
          <div class="OrderID">订单号</div>
          <div class="MyEvaluate">
            <div>单价</div>
            <div>数量</div>
            <div>买付款</div>
          </div>
        </div>
        <div style="overflow: hidden scroll;width:100%;height:60vh;">
          <div v-for="(item, index) in 3" :key="index" style="overflow:  hidden scroll;width:100%;height:30vh;">
            <div>2020年8月</div>
            <div class="OrderInformationBox" v-for="(item, index) in 3" :key="index">
              <div class="OrderInformation">
                <img src="../../../assets/PersonalCenter/goods.png" alt="" style="margin-left: 2vh;">
                <div class="TitleTime">
                  <div class="ShopTitle">文化块块</div>
                  <div>2022-10-10</div>
                </div>
                <div class="OrderNumber">订单号：2786115544331</div>
                <div class="Money">
                  <div style=" display:flex; flex-direction: column; margin-left:-0.2vw;height:8vh;">
                    <div
                      style="color: rgba(159, 159, 159, 1);font-size: 0.8vw;margin-top:1.5vh;text-decoration:line-through;display: flex;justify-content: center;">
                      ￥998</div>
                    <div style="color:rgba(48, 48, 48, 1);margin-top:0.4vh;">￥298</div>
                  </div>
                  <div style="color: rgba(48, 48, 48, 1);">1</div>
                  <div style="color: rgba(48, 48, 48, 1);">￥289</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.BigBoxOne {
  width: 99%;
  height: 99%;
  display: flex;
  justify-content: center;
  align-items: center;

  .ConBox {
    width: 94%;
    height: 96%;

    .MyPingJia {
      margin: 3vh 6vh;
      font-size: 24px;
    }

    .Con {
      width: 92.5%;
      height: 80%;
      margin: 0 auto;

      .SouSBox {
        width: 35vw;
        height: 5vh;
        border: 2px solid rgb(153, 153, 153);
        display: flex;
        align-items: center;

        img {
          height: 50%;
          margin: 0 1.5vh;
        }

        input {
          border: none;
          outline: none;
          height: 100%;
          width: 90%;
          font-size: 2vh;
          background-color: rgba(255, 255, 255, 0);
          color: rgba(173, 173, 173, 1);
          text-indent: 0.5vw;
        }
      }

      .TiTle {
        font-size: 2vh;
        height: 6vh;
        margin: 3vh 0 2vh 0;
        display: flex;
        align-items: center;
        background-color: rgba(242, 242, 242, 1);

        .ShopInformation {
          width: 30%;
          display: flex;
          justify-content: center;
          color: rgba(14, 14, 14, 1);
        }

        .OrderID {
          width: 20%;
          display: flex;
          justify-content: center;
          color: rgba(14, 14, 14, 1);
        }

        .MyEvaluate {
          width: 50%;
          display: flex;
          justify-content: space-around;
          color: rgba(14, 14, 14, 1);
        }
      }

      .OrderInformationBox {
        margin: 1.5vh 0;
        font-size: 2vh;
        height: 15vh;
        width: 100%;
        background-color: rgba(255, 255, 255, 1);
        display: flex;
        align-items: center;
        justify-content: center;

        .OrderInformation {
          width: 100%;
          height: 75%;
          display: flex;

          img {
            height: 100%;
            width: 18vh;
          }
        }

        .TitleTime {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin: 0 2vh;
          width: 15%;

          .ShopTitle {
            display: -webkit-box; //将元素设为盒子伸缩模型显示
            -webkit-box-orient: vertical; //伸缩方向设为垂直方向
            -webkit-line-clamp: 1; //超出3行隐藏，并显示省略号
            overflow: hidden;
            color: rgba(48, 48, 48, 1);
          }
        }

        .OrderNumber {
          width: 18%;
          color: rgba(48, 48, 48, 1);
        }

        .Money {
          width: 51%;
          display: flex;
          justify-content: space-around;
          align-items: center;
        }
      }
    }
  }

  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 0.3vw;
    height: 0.1vh;
    background-color: rgba(213, 213, 213, 1);
  }

  /* 滚动条上的滚动滑块 */
  ::-webkit-scrollbar-thumb {
    height: 0.3vh;
    background-color: white;
    border-radius: 32px;
  }
}</style>